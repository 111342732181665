import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='footer'>
        <p>Basta Erwerbsloseninitiative 2022, <span className='underline italic'><Link to="/datenschutz" >Impressum und Datenschutz hier </Link></span></p>
    </div>
  )
}
