import React from 'react'
import { Link } from 'react-router-dom'

import contactLogo from '../assets/contact.png'
import counselLogo from '../assets/conversation.png';
import aboutLogo from '../assets/about.png'


export default function MenuHero({outside}) {
  return (
    
    <div className={`menuHero ${outside ? 'menuHeroOut' : ''}`}>
        <ul>
        <li>
            <Link to="/beratung" >
              <div className='heroItem' >
                <img src={counselLogo} alt="logo Beratung" />
               <h2>Beratung</h2>
              </div>
                  
            </Link>
        </li>  
            <li>
            <Link to="/ueber" >
            <div className='heroItem'>
              <img src={aboutLogo} alt="logo über uns"></img>
              <h2>Über uns</h2>
            </div>
            </Link>
            </li>
            <li>
            <Link to="kontakt">
            <div className='heroItem'>
              <img src={contactLogo} alt="logo Kontakt"></img>
              <h2>Kontakt</h2>
            </div>
            </Link>
            </li>
        </ul>
    </div>
  )
}
