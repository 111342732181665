import { PortableText } from "@portabletext/react";
import React from "react";
import counselLogo from "../assets/conversation.png";
import PlacesWrapper from "../components/PlacesWrapper";
import { portBlockCenterHead, portCenterHead, portList } from "../helpers/misc";
import usePages from "../hooks/usePages";

function Counseling() {
  const pageTitle = "Beratung bei uns";
  const { page, loading } = usePages(pageTitle);

  return (
    <>
      {loading ? (
        <h3>Loading</h3>
      ) : (
        <div className="counselingBox page">
          <div className="chapterHead">
            <img src={counselLogo} alt="logo Beratung" />
            <h2>{page.title["de"]}</h2>
          </div>
          <div className="chapterIntro">
            <PortableText 
              value={page.content["de"]} 
              components={{
                ...portList,
                ...portBlockCenterHead
              }}
              />
          </div>
          <PlacesWrapper />
        </div>
      )}
    </>
  );
}

export default Counseling;
