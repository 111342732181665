import React  from 'react'
import {useNavigate } from 'react-router-dom'
import MenuDesktop from './MenuDesktop'


function Menu(props) {
  const {toggle, setToggle} = props
  const navigate = useNavigate()
   
function handleClick(target){
  setToggle(false)
  navigate(`/${target}`)
}

    return (
    <>
    <div className={`menuContainer ${toggle ? 'down' : 'up'}`}>
        <ul className='menuList'>
          <li onClick={() => handleClick('beratung')}>Beratung</li> 
          <li onClick={() => handleClick('ueber')}>   Über uns</li> 
          <li onClick={() => handleClick('kontakt')}>  Kontakt</li> 
        </ul>
        <div className="closeBtn" onClick={() => setToggle(false)}>
          <p >X</p>
        </div>
    </div>
      {/* {scrolledDown || location.pathname !== '/' ? <MenuDesktop visible={true} /> : <></>} */}
      <MenuDesktop visible={true} />
      {/* <div className={`menuContainerDesktop`}>
        <ul className='menuListDesktop'>
          <Link to="/beratung" ><li>Counseling</li></Link>
          <Link to="/ueber" > <li>About us</li></Link>
          <Link to="kontakt"> <li>Contact</li></Link>
        </ul>
      </div> */}
    </>
  )
}

export default Menu