import { PortableText } from "@portabletext/react";
import React from "react";
import { useParams } from "react-router-dom";
import { portList, getDate } from "../helpers/misc";
import useArticles from "../hooks/useArticles";
import fileLogo from "../assets/file.png";
import Zurueck from "./Zurueck";


function ArtikelLg() {
  const id = useParams();
  const { list, getId, loading } = useArticles();

  React.useEffect(() => {
    getId(id);
  }, [getId, id]);

  return (
    <div className="article_lg">
      {loading ? (
        <h3>LOADING</h3>
      ) : (
        <>
          <div className="articleHead">
            <Zurueck />
            <h2>{list?.headline}</h2>
            <h3 className="subHead">{list?.subHead}</h3>
            <p className="date">Basta vom {getDate(list?.created)}</p>
          </div>

          <div className="articleBody">
            {list?.imageUrl && (
              <figure className="articleImg">
                <img
                  alt="bild zum Artikel"
                  src={list.imageUrl + `?w=400`}
                ></img>
                {list.imgSub && <figcaption>{list.imgSub}</figcaption>}
              </figure>
            )}
            <div className="articleText">
              <PortableText value={list?.mainText.de} components={portList} />
            </div>
            {list.files && list?.files.length > 0 && (
              <div className="fileView">
                <h3>Anhänge</h3>
                {list?.files.map((file) => {
                  return (
                    <div className="fileItem">
                        <a href={file.link} rel="noreferrer" target="_blank">
                          <img src={fileLogo} alt="" />
                          <p>{file.descr}</p>
                        </a>
                      </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ArtikelLg;
