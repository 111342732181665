import React from 'react'

function Burger(props) {

  const {setToggle} = props;

  return (
    <div className='burgerIcon' onClick={() => setToggle(true)}>
        <span></span>
        <span></span>
        <span></span>
        <p>MENU</p>
    </div>
  )
}

export default Burger