import React from "react"
import { getURL } from "../helpers/misc"

const PageContext = React.createContext()


export function PagesProvider(props){

    const [pages, setPages] = React.useState(null)
    const [loading, setLoading] = React.useState(true)

    const QUERY = encodeURIComponent(' *[_type == "pages"]')
    const URL = getURL(QUERY)

    React.useEffect(() => {
        fetch(URL)
            .then( res => res.json())
            .then( data => {
                const tmpObj = {}
                for (const page of data.result){
                    const title_de = page.site.de
                    tmpObj[title_de] = {
                        content: page.content,
                        title: page.site
                }}
                setPages(tmpObj)
                setLoading(false)
            })
        
    },[URL])
    

    const context = {
        pages: pages,
        loading: loading,
        get: (site) => { 
            if (pages) return pages[site] || null
            else return null
        }
    }

    return(
        <PageContext.Provider value={context}>
            {props.children}
        </PageContext.Provider>
    ) 

}

export default function usePages(page){
    const context = React.useContext(PageContext)
    return {page: context.get(page), loading: context.loading}
}