import React, {useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import { PageContext } from '../context/PageContext'


export default function MenuDesktop({visible}) {
    const {scrolledDown, location} = useContext(PageContext)
    
    const navigate = useNavigate()
      
    function handleClick(target){
      navigate(`/${target}`)
    }

    return (
    <div className={`menuContainerDesktop ${scrolledDown || location.pathname!== '/' ? 'menuContainerDesktopIn' : 'menuContainerDesktopOut' }`}>
        <ul className='menuListDesktop'>
          <li onClick={() => handleClick('beratung')}>Beratung</li>
          <li onClick={() => handleClick('ueber')}> Über uns</li>
          <li onClick={() => handleClick('kontakt')}>Kontakt</li> 
        </ul>
      </div>
  )
}
