import React from "react";
import placeLogo from "../assets/place.png";
import { PortableText } from "@portabletext/react";
import { getDate } from "../helpers/misc";

function Place({ data}) {
  let {place,happens, pausedFinal} = data
  let {address, time, lang, note, booking, bookingBlock } = data.counselData;

  return (
    <>
      {!happens && <div className="paused">
        <h3>Die Beratung fällt aus, nächste Beratung am {  getDate(pausedFinal)} </h3>
      </div>}
      <div className="placeHead">
        <img src={placeLogo} alt="icon for counseling place"></img>
        <div className="seperator"></div>
        <h3>{place}</h3>
        <div className="seperator"></div>
      </div>
      <div className="placeBody">
        <ul className="placeDescriptionList">
          <li>
            <div className="placeDescriptionItem">
              <h4>Wo?</h4>
              <p>{address}</p>
            </div>
          </li>
          <li>
            <div className="placeDescriptionItem">
              <h4>Geöffnet:</h4>
              <p>{time}</p>
            </div>
          </li>
          <li>
            <div className="placeDescriptionItem">
              <h4>Sprachen:</h4>
              <p>{lang}</p>
            </div>
          </li>
          <li>
            <div className="placeDescriptionItem">
              <h4>Hinweise:</h4>
              <PortableText value={note["de"]} />

              <div className="counselBooking">
                {booking && bookingBlock?.links?.map((el) => {
                  return (
                    <a
                      href={el.link}
                      rel="noreferrer"
                      target="_blank"
                      key={el._key}
                    >
                      <span className="bookingButton">{el.text}</span>
                    </a>
                  );
                })}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Place;

//   <div className="counselBooking">
//                   <a href="http://forms.gle/EeCPJso1DYpzp4N46" target="_blank">
//                     <span className="bookingButton">
//                       Termin um 18 Uhr buchen
//                     </span>
//                   </a>
//                   <a href="http://forms.gle/3jWuJ75J4SsxpiVG8" target="_blank">
//                     <span className="bookingButton">
//                       Termin um 19 Uhr buchen
//                     </span>
//                   </a>
//                 </div>
