import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './styles/main.scss';

import { BrowserRouter } from 'react-router-dom';
import { PageContextProvider} from './context/PageContext';
import ScrollTop from './helpers/ScrollToTop';
import { PagesProvider } from './hooks/usePages';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
     <PagesProvider>

      <PageContextProvider>
        <ScrollTop />
        <App />
      </PageContextProvider>
     </PagesProvider>
    
    </BrowserRouter>
  </React.StrictMode>
);


