import React, {useContext} from 'react'
import Aktuell from '../components/Aktuell';
import Hero from '../components/Hero';
import TwitterBox from '../components/TwitterBox';
import { PageContext } from '../context/PageContext'
import MenuHero from '../components/MenuHero';


function Landing() {
  
  const {scrolledDown} = useContext(PageContext);
  
  return (
    <div className='landingPage page'>
      <Hero />
      <MenuHero outside={scrolledDown}/> 
      <Aktuell />
      {/* <TwitterBox />  */}
    </div>
  )
}

export default Landing