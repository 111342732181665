import React from "react";
import aktuelLogo from "../assets/new.png";
import ArtikelPre from "./ArtikelPre";
import useArticles from "../hooks/useArticles";
import { PortableText } from "@portabletext/react";
import { getDate, portList } from "../helpers/misc";
import { Link } from "react-router-dom";


export default function Aktuell() {
  const {list, getAll, loading} = useArticles();

  React.useEffect(() => {
    const getData = async () => {
      await getAll()
    }  
    getData()
  },[getAll])

  return (
    <div className="aktuellWrapper">
      <div className="chapterHead">
        <img src={aktuelLogo} alt="logo aktuell" />
        <h2>Aktuelles</h2>
      </div>

      {loading ? <h3>LOADING</h3> : list?.map((article) => {
        return (
          <ArtikelPre key={article._id}>
            <div className="preHead">
              <h3>{article.headline}</h3>
              <div className="preInfo">
                <span>{getDate(article._createdAt)}</span>
                <span></span>
              </div>
            </div>
            <div className="preBody">
              <PortableText value={article.body.de} components={portList} />
              {article.longArticle && <Link 
                className="readOn"
                to={`/artikel/${article._id}`} >weiterlesen</Link>}
           {/* <ReadOn articleId={article._id} head={article.headline.replace(/[\s.]+/g, '-')}/> */}
            </div>
          </ArtikelPre>
        );
      })}
    </div>
  );
}
