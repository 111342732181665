import React from 'react'
import { PortableText } from '@portabletext/react'
import { portList } from '../helpers/misc'
import aboutLogo from '../assets/about.png'
import usePages from '../hooks/usePages'
import logoNetzwerk from '../assets/netzwerkselbsthilfelogo.png'

export default function About() {

  const pageTitle = "Über uns"  
  const {page, loading} = usePages(pageTitle)
 
  return (
    <>
    {
      loading ? <h3>LOADING</h3> : 
  
    <div className='aboutBox page'>
        <div className="chapterHead">
            <img src={aboutLogo} alt="logo über uns"></img>
            <h2>{page?.title['de']}</h2>
        </div>
        <div className="chapterIntro">
          <PortableText 
            value={page?.content['de']} 
            components={portList}
            />
      
        </div>
        <div className="support">
          <p>Wir werden unterstützt vom Netzwerk Selbsthilfe Berlin</p>
          <a href="https://netzwerk-selbsthilfe.de/" target='_blank' rel='noreferrer'>
            <img src={logoNetzwerk} alt="Logo Netzwerk Selbsthilfe" />
          </a>
        </div>
    </div>
  
    }
    
    </>
  )
}
