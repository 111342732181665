import { PortableText } from "@portabletext/react";
import React, { useState } from "react";
import contactLogo from "../assets/contact.png";
import ContactForm from "../components/ContactForm";
import FormHandler from "../components/FormHandler";
import MailSent from "../components/MailSent";
import usePages from "../hooks/usePages";

export default function Contact() {
  const [formSent, setFormSent] = useState(false);
  const lang = "de";
  const pageTitle = "Kontakt";
  const { page, loading } = usePages(pageTitle);

  return (
    <>
      {loading ? (
        <h3>LOADING</h3>
      ) : (
        <div className="contactBox">
          <div className="chapterHead">
            <img src={contactLogo} alt="logo kontakt"></img>
            <h2>{page?.title[lang]}</h2>
          </div>
          <div className="chapterIntro">
            <PortableText 
              value={page?.content[lang]}
              />
          </div>
          {/* {formSent ? (
            <MailSent />
          ) : (
            <FormHandler formSent={formSent} setFormSent={setFormSent}>
              <ContactForm />
            </FormHandler>
          )} */}
        </div>
      )}
    </>
  );
}
