import React from "react";
import CounselPlace from "./CounselPlace";
import Place from "./Place";
import useCounseling from "../hooks/useCounseling";

export default function PlacesWrapper() {
  
  const places = useCounseling()

  return (
    <div className="placesWrapper ">
      
      {places.loading ?
      <h3>Lade Beratungsorte...</h3> :
      places?.list?.map( (place,i) => {
        return(
          
          !place.paused &&
            <CounselPlace key={place._id} happens={place.happens}>
              <Place data={place} />
            </CounselPlace>
          
          
          
        )
      })
      }
    </div>
  );
}
