import * as React from 'react'
import { getURL } from '../helpers/misc'

export default function useCounseling(){

const QUERY = encodeURIComponent('* [_type == "counseling"]')
const URL = getURL(QUERY)

const [list, setList] = React.useState()
const [loading, setLoading] = React.useState(true)

React.useEffect(() => {
    fetch(URL)
        .then(res => res.json())
        .then(data => {
            setList(data.result)
            setLoading(false)
        } )
},[URL])

return {list, loading}

}