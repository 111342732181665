import React, { useEffect, useRef, useState } from 'react'
import slideViele from '../assets/slideViele.JPG';
import slideBuero from '../assets/slideBuero.JPG';
import slideFewo from '../assets/slideFewo2.png'; 

export default function Hero() {
  
    const [slideIndex, setSlideIndex] = useState(0);
    const timeoutRef = useRef(null);
    
    function resetTimeout() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }

    useEffect (( ) => {
        resetTimeout();
        timeoutRef.current = setTimeout(() => {
            
            setSlideIndex((prevIndex) => prevIndex === 2 ? 0 : prevIndex + 1)
            // setSlideIndex((prevIndex) => prevIndex+1 )
        }, 3000);
        return () => {
            resetTimeout();
          };
    }, [slideIndex])
  
    return (
    <div className='heroWrapper'>
        <div className="heroViewer" style={{transform: `translate3d(${-slideIndex * 33.3}%, 0, 0)` }}>
            <div className="slide">
                <span>beraten</span>    
                <img src={slideBuero} alt="" />
            </div>
            <div className="slide">
                <span>organisieren</span>    
                <img src={slideViele} alt="" />
            </div>
            <div className="slide">
                
                <span>streiken</span>    
                <img src={slideFewo} alt="" />
            </div>
        {/* {colors.map((backgroundColor, index) => (
          <div className="slide" key={index} style={{ backgroundColor }}/>
        ))} */}
        </div>



    </div>
  )
}
