import React, { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export const PageContext = createContext()


export const PageContextProvider = ( {children}) => {
    const [cookie, setCookie] = useState('');
    const [scrolledDown, setScrolledDown] = useState(false);
    const [shadowFilter, setShadowFilter] = useState(false);
    // const [current, setCurrent] = useState('land')
    const location = useLocation()

    const handleScroll = () => {
      const position = window.scrollY;
      // Change Logo by scrolling
      if (position > 80) {
        setScrolledDown(() => true);
      } else {
        setScrolledDown(() => false);
      }
      if (position > 240){
      
        setShadowFilter(() => true);
      } else {
        setShadowFilter(() => false)
      }
    }
    
  
    useEffect (() => {
      window.addEventListener('scroll', handleScroll, {passive:true});
      if(localStorage.getItem('cookie') === 'yes'){
        setCookie('yes');
      }
    },[])

    // useEffect(() => {
    //     console.log('Print location: ', location.pathname);
    // }, [location])





    return (
        <PageContext.Provider value={{cookie, setCookie, scrolledDown, shadowFilter, location}}>
            {children}
        </PageContext.Provider>
    )
}

