import './App.css';
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';


import Header from './components/Header';
import Footer from './components/Footer';
import Counseling from './pages/Counseling';
import Landing from './pages/Landing';
import About from './pages/About';
import Contact from './pages/Contact';
import DataSec from './pages/DataSec';
import ArtikelLg from './components/Artikel_lg';


function App() {
  
   
  return (
    
    <div className="App">
     <Header />
     <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/beratung" element={ <Counseling />} />
      <Route path="/ueber" element={ <About />} />
      <Route path="/kontakt" element={ <Contact />} />
      <Route path="/artikel/:id" element={ <ArtikelLg />} />
      <Route 
        path="/datenschutz" 
        element={ <DataSec />} />
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
     </Routes> 
     <Footer /> 
    </div>
  );
}

export default App;
