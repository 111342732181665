import * as React from 'react'
import { getURL } from '../helpers/misc'



export default function useRecords(){

    
    const [list, setList] = React.useState()
    const [loading, setLoading] = React.useState(true)
    

    const getId = React.useCallback(async({id}) => {
        console.log(id)
        const QUERY = encodeURIComponent(
            `
               * [_type == "article" 
                     && _id == "${id}"][0]
                        {
                            headline,subHead, mainText,
                            "imageUrl": image.asset->url,
                            imgSub,
                            "files":file[]{
                                "link": asset->url,
                                "descr": fileDescr
                            },
                            "created": _createdAt
                        }
            `)

        const data = await fetch(getURL(QUERY))
        const {result} = await data.json()
        if(result){
            setList(result)
            setLoading(false)
        }
    },[])
    

    const getAll = React.useCallback(async () =>{
        const QUERY = encodeURIComponent('* [_type == "article"] | order(_createdAt desc)')
        
        const data = await fetch( getURL(QUERY) )
        const {result} = await data.json()
        if (result){
            setList(result)
            setLoading(false)
        }
},[]) 

// async function getAll(page){
//     const data = await fetch(URL)
//     const {result} = await data.json()
//     if (result){
//         console.log(result)
//         setList(result)
//         setLoading(false)

//     }
    //   fetch(URL)
    // .then( res => res.json())
    // .then( data => {
    //     setList(data.result)})
    
// }



// React.useEffect(() => {
//     fetch(URL)
//     .then( res => res.json())
//     .then( data => {
//         setList(data.result)})
//     console.log('use Effect runs')
// },[URL])



return {list, getId, getAll, loading}
}