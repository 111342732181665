import React, {useContext, useState} from 'react'
import Burger from './Burger'
import Logo from './Logo'
import Menu from './Menu'
import { Link } from 'react-router-dom'
import { PageContext } from '../context/PageContext'
import Headline from './Headline'


function Header() {
    
    const [menuToggle, setMenuToggle] = useState(false);
    const {shadowFilter, scrolledDown, location} = useContext(PageContext)
    

    // const clickBurger =() => {
    //     setMenuToggle(() => !menuToggle);
    // }
  

    return (
    <div className={`header ${shadowFilter ? 'filterShadow' : ''}`}>
        <Link to="/" aria-label='back to home'>
          <div className="logoContainer">
            <Logo />
          
          </div>
        </Link>
        <Menu toggle={menuToggle} setToggle={setMenuToggle}/>
        <Burger setToggle={setMenuToggle} />
        {location.pathname === '/' && !scrolledDown ? <Headline inside={true} /> : <Headline inside={false}></Headline>}
        
    </div>
  )
}

export default Header