import React from "react";
import { useNavigate } from "react-router-dom";
import backIcon from '../assets/back.png'

function Zurueck() {
  const navigate = useNavigate();

  return (
    <div className="backIcon" onClick={() => navigate(-1)}>
      <img src={backIcon} alt="zurück icon"/>
      <span>zurück</span>
    </div>
  );
}

export default Zurueck;
