import React, {useContext} from 'react'
import logo from '../assets/logo.png';
import { PageContext } from '../context/PageContext'


function Logo(props) {
  const {scrolledDown} = useContext(PageContext)

  return (
    <div className={`logo ${scrolledDown ? 'logoSmall' : 'logoBig'}`}>
        <img src={logo} className='logo_img' alt="Basta Logo" />
    </div>
  )
}

export default Logo