export function getDate(dateString) {
  const newDate = new Date(dateString);
  const month = newDate.getMonth() + 1;
  return `${newDate.getDate()}.${
    month < 10 ? "" + month : month
  }.${newDate.getFullYear()}`;
}

export function getURL(QUERY) {
  const PROJECT_ID = "xof20vmk";
  const DATASET = "production";

  return `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;
}

export const portList = {
  list: {
    bullet: ({ children }) => <ul className="list">{children}</ul>,
  },
};

export const portBlockCenterHead = {
  block: {
    h4: ({ children }) => <h4 className="centerText">{children}</h4>,
  },
};
